import {
  ActionTypeEnum,
  FacebookMessengerButton,
  FacebookMessengerSkinsUrlPreview,
  Property,
} from '@/types';
import { useI18n } from 'vue-i18n';
import VariablesList, { Variable } from '@/components/template-builder/utils/variables-list';
// eslint-disable-next-line import/no-cycle
import { facebookButtonStructure } from '@/components/template-builder/config/templates/facebookmessenger/raw-html-config';
import beautifier from 'js-beautify';
// eslint-disable-next-line import/no-cycle
import {
  TemplateEditorState,
  updateSectionsInState,
  replaceTranslationInState,
  removeUnusedTranslations,
  htmlHasSmartlist,
  addSmartListOriginalCode, setPerfectSizeForImage,
} from '@/composables/template-editor/TemplateEditor';

import { getFirstParent, getParents } from '@/helpers';
// eslint-disable-next-line import/no-cycle
import { store } from '@/store';
import {
  BORDERS_PROPERTIES_TO_REMOVE_IF_ZERO,
  LINE_HEIGHT_POSSIBLE_VALUES,
  POPIN_AUTO_CLOSE_ATTRIBUTE,
  POPIN_AUTO_CLOSE_CLASS,
  TRANSLATION_ATTRIBUTE,
} from '@/components/template-builder/utils/constants';

import {
  UserState,
} from '@/composables/User';

import { TemplateTypeEnum } from '@/composables/shop/Templates';
// eslint-disable-next-line import/no-cycle
import { getCssPropertyByName } from '@/components/template-builder/getters';
// eslint-disable-next-line import/no-cycle
import {
  getTemplateIframeDocument, isDisplayTemplate,
  isEmailTemplate,
  isPushTemplate, reconstructDynamicPersistentMobileCss,
} from '../utils/helpers';
// eslint-disable-next-line import/no-cycle
import {
  addOrUpdateDynamicStyle,
  getDynamicStylesForActiveSection,
  getDynamicStylesForSelector,
  removeDynamicStyle,
} from '../utils/parser';
// eslint-disable-next-line import/no-cycle
import { sanitizeTranslationMarkup, setTranslationInState, generateTranslations } from '../utils/translate';

// eslint-disable-next-line import/no-cycle
export * from './social-networks';
// eslint-disable-next-line import/no-cycle
export * from './media-display';
// eslint-disable-next-line import/no-cycle
export * from './link';
// eslint-disable-next-line import/no-cycle
export * from './smart-product-list';
// eslint-disable-next-line import/no-cycle
export * from './form';

export const cleanCssByProperty = (selector: string, property: Property) => {
  let propertyRemoved = false;

  // Get all sections of current template
  const sections = TemplateEditorState.template?.sections;

  if (sections && sections.length) {
    sections.forEach((section) => {
      // Get dunamic styles of current section
      const styles = getDynamicStylesForActiveSection(section.id_template_elements);
      const { sheet } = styles;

      if (sheet) {
        if (sheet.cssRules && sheet.cssRules.length) {
          let index = 0;
          Object.values(sheet.cssRules).forEach((styleRule: any) => {
            // We check if there are many properties in the rule
            if ((styleRule.cssText.match(new RegExp(';', 'g')) ?? []).length > 1) {
              // We split the rule to remove the desired property
              const explodedRule = styleRule.cssText.match(new RegExp('^([^{]+{)([^}]+)}'));

              if (explodedRule) {
                const ruleSelector = explodedRule[1];
                const ruleProperties = explodedRule[2].trim().split(';');

                if (ruleProperties.length) {
                  const finalProperties: string[] = [];
                  let propertyChangedForThieRule = false;
                  let index2 = 0;
                  Object.values(ruleProperties).forEach((ruleProperty: any) => {
                    if ((new RegExp(`^${property.name}\\s*:`)).test(ruleProperty.trim()) && ruleSelector.replace('{', '').trim() === selector) {
                      // The current property matches the desired property, so we need to remove it
                      delete ruleProperties[index2];
                      propertyRemoved = true;
                      propertyChangedForThieRule = true;
                    } else if (ruleProperty.trim() !== '') {
                      finalProperties.push(ruleProperty.trim());
                    }
                    index2 += 1;
                  });

                  if (propertyChangedForThieRule && finalProperties.length) {
                    const finalRule = `${ruleSelector} ${finalProperties.join('; ')}; }`;
                    sheet.deleteRule(index);
                    // eslint-disable-next-line no-param-reassign
                    index -= 1;
                    sheet.insertRule(finalRule, index);
                  }
                }
              }
            } else if ((new RegExp(`{\\s*${property.name}\\s*:`)).test(styleRule.cssText) && styleRule.selectorText === selector) {
              // If not, and if the CSS rule text matches the property, we remove the rule
              sheet.deleteRule(index);
              // eslint-disable-next-line no-param-reassign
              index -= 1;
              propertyRemoved = true;
            }
            index += 1;
          });
        }
      }
    });
  }

  if (propertyRemoved) {
    // If at least a property has been removed, we update the sections in state
    updateSectionsInState();
  }
};

export const resetCssPropertyWithoutPseudoClasses = (selector: string, property: Property) => {
  if (undefined !== property.value) {
    const { name } = property;
    let newSelector = selector;
    newSelector = newSelector.replace(new RegExp('(:[a-zA-Z-]+\\([-:.a-zA-Z#]+\\))+', 'g'), '');
    addOrUpdateDynamicStyle(newSelector, { [name]: '0' });
  }
};

export const setCssProperty = (selector: string, property: Property) => {
  if (undefined !== property.value) {
    // eslint-disable-next-line prefer-const
    let { name, value } = property;
    let updateDynamicStyle = true;
    if (value) {
      if (name === 'max-width' && new RegExp(' img').test(selector) && isEmailTemplate(TemplateEditorState.template?.type)) {
        // In case of changing the max-width property of an image, we also change inline properties
        const template = getTemplateIframeDocument();
        const elements = template?.querySelectorAll(selector);
        elements.forEach((element: any) => {
          if (element && element.tagName === 'IMG') {
            setPerfectSizeForImage(element, value);
            updateDynamicStyle = false;
          }
        });
      } else if (name === 'vertical-align' && isEmailTemplate(TemplateEditorState.template?.type)) {
        // If property is vertical-align, we also set the valign property on the element
        const valign = value === 'middle' ? 'center' : value;
        const template = getTemplateIframeDocument();
        const element = template?.querySelector(selector) as HTMLElement;

        if (element) {
          element.setAttribute('valign', valign);
        }
      } else if (['background', 'background-image', 'background-repeat', 'background-position-x', 'background-position-y', 'background-size'].includes(name)) {
        const originalBackgroundImage = getCssPropertyByName(selector, 'background-image', true);
        let backgroundImage = (name === 'background-image' ? value.replace('!important', '').trim() : getCssPropertyByName(selector, 'background-image', true));
        let backgroundRepeat = (name === 'background-repeat' ? value.replace('!important', '').trim() : getCssPropertyByName(selector, 'background-repeat', true));
        let backgroundPositionX = (name === 'background-position-x' ? value.replace('!important', '').trim() : getCssPropertyByName(selector, 'background-position-x', true));
        let backgroundPositionY = (name === 'background-position-y' ? value.replace('!important', '').trim() : getCssPropertyByName(selector, 'background-position-y', true));
        let backgroundSize = (name === 'background-size' ? value.replace('!important', '').trim() : getCssPropertyByName(selector, 'background-size', true));
        let addImportant = false;
        let originalBackgroundProperty: string|boolean = false;
        let originalBackgroundPropertyValue: string|boolean = false;

        // Force background property in order to recalculate all
        if ((name !== 'background' && typeof backgroundImage === 'string' && backgroundImage && backgroundImage !== 'none' && backgroundImage.match(/url\(/))
          || (typeof backgroundImage === 'string' && backgroundImage && backgroundImage === 'none')) {
          originalBackgroundProperty = name;
          originalBackgroundPropertyValue = value;
          if (name !== 'background') {
            name = 'background';
            value = getCssPropertyByName(selector, 'background-color', true) ?? '';
          }
          // eslint-disable-next-line max-len
          if (typeof originalBackgroundImage === 'string' && originalBackgroundImage && originalBackgroundImage !== 'none' && originalBackgroundImage.match(/(linear|radial)-gradient/)) {
            value = originalBackgroundImage.replace(/.*((linear|radial)-gradient.*\))$/, '$1');
          }
        }
        // Keep image background
        if (typeof backgroundImage === 'string' && backgroundImage && backgroundImage !== 'none' && backgroundImage.match(/url\(/)) {
          if (backgroundImage.match(/, (linear|radial)-gradient/)) {
            backgroundImage = backgroundImage.replace(/, (linear|radial)-gradient.*\)$/, '');
            if (typeof backgroundRepeat === 'string') {
              backgroundRepeat = backgroundRepeat.replace(/,.*$/, '');
            }
            if (typeof backgroundPositionX === 'string') {
              backgroundPositionX = backgroundPositionX.replace(/,.*$/, '');
            }
            if (typeof backgroundPositionY === 'string') {
              backgroundPositionY = backgroundPositionY.replace(/,.*$/, '');
            }
            if (typeof backgroundSize === 'string') {
              backgroundSize = backgroundSize.replace(/,.*$/, '');
            }
          }
          if (value.match('!important')) {
            addImportant = true;
            value = value.replace('!important', '').trim();
          }
          let isGradient = false;
          if (value.match('gradient')) {
            isGradient = value;
            value = `${backgroundImage}`;
          } else {
            value = `${value} ${backgroundImage}`;
          }

          if (backgroundRepeat) {
            value = ` ${value} ${backgroundRepeat}`;
          }
          if (backgroundPositionX) {
            value = `${value} ${backgroundPositionX}`;
          }
          if (backgroundPositionY) {
            value = `${value} ${backgroundPositionY}`;
          }
          if (backgroundSize) {
            value = `${value} / ${backgroundSize}`;
          }
          if (isGradient) {
            value = `${value}, ${isGradient}`;
          }
        }
        if (addImportant) {
          value = `${value} !important`;
        }
        if (typeof originalBackgroundProperty === 'string' && typeof originalBackgroundPropertyValue === 'string') {
          addOrUpdateDynamicStyle(selector, { [originalBackgroundProperty]: originalBackgroundPropertyValue });
        }
      }

      if (updateDynamicStyle) {
        addOrUpdateDynamicStyle(selector, { [name]: value });
      }
    } else {
      // The rule is empty, we need to remove the rule
      cleanCssByProperty(selector, property);
    }
  }
};

export const setCssPropertyAndRemoveIfZero = (selector: string, property: Property) => {
  if (undefined !== property.value) {
    const { name, value } = property;
    if (value) {
      if (value === 0 || value === '0px' || value === '0%') {
        // If value equals 0 we remove the rule
        removeDynamicStyle(selector, { [name]: value });

        // We then check if no parent is defining a rule that may affect the deleted property
        const template = getTemplateIframeDocument();
        const element = template?.querySelector(selector) as HTMLElement;

        if (element) {
          const css = window.getComputedStyle(element);
          const appliedRule = css.getPropertyValue(name);

          // If true, we need to restore the rule, else we can continue
          if (appliedRule && parseInt(appliedRule, 10) !== 0) {
            addOrUpdateDynamicStyle(selector, { [name]: value });
          } else if (name === 'border-width') {
            // If property is border-width, we also remove the other properties
            BORDERS_PROPERTIES_TO_REMOVE_IF_ZERO.forEach((borderProperty) => {
              removeDynamicStyle(selector, { [borderProperty]: '' });
            });
          }
        }
      } else {
        setCssProperty(selector, property);
      }
    } else {
      // The rule is empty, we need to remove the rule
      cleanCssByProperty(selector, property);
    }
  }
};

export const setCssPropertyWithImportant = (selector: string, property: Property) => {
  const updatedProperty = { ...property };
  updatedProperty.value = `${property.value} !important`;
  setCssProperty(selector, updatedProperty);
};

export const setCssPropertyWithImportantAndRemoveIfZero = (selector: string, property: Property) => {
  const updatedProperty = { ...property };
  updatedProperty.value = `${property.value} !important`;
  setCssPropertyAndRemoveIfZero(selector, updatedProperty);
};
let handleTimeout: ReturnType<typeof setTimeout> | null;
export const setAttribute = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const elements: Array<HTMLElement> = Array.from(template?.querySelectorAll(selector));
  if (elements.length > 0) {
    elements.forEach((element: HTMLElement) => {
      if (property.value !== null) {
        // If we change image src attribute, we need to fix the max-width according to the width of the image or the parent
        if (property.name === 'src') {
          // Retrieve prev size of image
          const prevImage = element as HTMLImageElement;
          let prevImgWidth: string | number;
          if (prevImage.width) {
            prevImgWidth = prevImage.width;
          } else {
            prevImgWidth = 0;
          }
          // Set no src to remove image and calculate parent's width
          element.setAttribute(property.name, '');

          let selectorParent;
          switch (TemplateEditorState.template?.type) {
            case TemplateTypeEnum.POPUP:
            case TemplateTypeEnum.EMBED:
              selectorParent = '.spm_widget_display_image';
              break;
            case TemplateTypeEnum.PUSHNOTIFICATIONS:
              selectorParent = '.push-notification-image';
              break;
            case TemplateTypeEnum.EMAIL:
            default:
              selectorParent = '.spm_widget_image';
              break;
          }

          let parent = getParents(element, selectorParent);

          if ((!parent || parent.length === 0) && isPushTemplate(TemplateEditorState.template?.type)) {
            // Image is icon, not large image
            parent = getParents(element, '.push-notification-icon');
          }

          if (parent && parent.length) {
            const parentElement = parent[0];
            const parentWidth = parentElement.offsetWidth;

            // We set the correct src of the image
            element.setAttribute(property.name, property.value);
            // eslint-disable-next-line no-param-reassign
            element.onload = () => {
              // When image is loaded, we calculate its width
              const image = element as HTMLImageElement;
              const imgWidth = image.naturalWidth;

              // We set the proper CSS rule according to the lowest calculated width
              const maxWidth = (imgWidth < parentWidth) ? imgWidth : parentWidth;
              if (!prevImgWidth || prevImgWidth > maxWidth) {
                addOrUpdateDynamicStyle(selector, { 'max-width': `${maxWidth}px` });
                element.setAttribute('width', maxWidth);
              }
              store.dispatch('liveEditor/refreshWidgetFields');
            };
          }
        } else {
          element.setAttribute(property.name, property.value);

          if (property.name === POPIN_AUTO_CLOSE_ATTRIBUTE) {
            if (property.value === '0' || property.value === '') {
              element.removeAttribute(property.name);
              element.classList.remove(POPIN_AUTO_CLOSE_CLASS);
            } else {
              element.classList.add(POPIN_AUTO_CLOSE_CLASS);
            }
          }
        }

        if (property.name !== POPIN_AUTO_CLOSE_ATTRIBUTE) {
          setTranslationInState(element, 'attribute', property.name);
        }
      }
    });
    if (property.value !== null) {
      // If we change data-mobilefontsizeincrease, we need to refresh css
      if (property.name === 'data-mobilefontsizeincrease') {
        if (handleTimeout) {
          clearTimeout(handleTimeout);
        }
        handleTimeout = setTimeout(async () => {
          reconstructDynamicPersistentMobileCss();
        }, 500);
      }
    }
  }
};

export const setCssButtonWidthProperty = (selector: string, property: Property) => {
  if (undefined !== property.value && (isEmailTemplate(TemplateEditorState.template?.type) || isDisplayTemplate(TemplateEditorState.template?.type))) {
    // eslint-disable-next-line prefer-const
    let { name, value } = property;
    // get padding left and right if width is auto
    if (value === 'auto') {
      let paddingSelector = selector;
      if (isEmailTemplate(TemplateEditorState.template?.type)) {
        paddingSelector += ' th';
      }
      const leftPadding = getCssPropertyByName(paddingSelector, 'padding-left', true);
      if (typeof leftPadding !== 'string' || (parseInt(leftPadding, 10) === 0)) {
        addOrUpdateDynamicStyle(paddingSelector, { 'padding-left': '10px' });
      }
      const rightPadding = getCssPropertyByName(paddingSelector, 'padding-right', true);
      if (typeof rightPadding !== 'string' || (parseInt(rightPadding, 10) === 0)) {
        addOrUpdateDynamicStyle(paddingSelector, { 'padding-right': '10px' });
      }
    }
    if (isDisplayTemplate(TemplateEditorState.template?.type) && selector.match('spm_generate_voucher_button')) {
      if (value === 'auto') {
        addOrUpdateDynamicStyle(selector, { display: 'inline-block' });
      } else {
        removeDynamicStyle(selector, { display: '' });
      }
    }
    if (isDisplayTemplate(TemplateEditorState.template?.type)) {
      setCssPropertyWithImportant(selector, property);
    } else {
      setCssProperty(selector, property);
    }
  }
};

export const setAttributeByName = (name: string) => (selector: string, property: Property) => {
  const updatedProperty = { ...property };
  updatedProperty.name = name;
  setAttribute(selector, updatedProperty);
};

export const setContent = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  if (element && undefined !== property.value) {
    // This setter can be involved by translation
    sanitizeTranslationMarkup(element).innerHTML = property.value;
    setTranslationInState(element, 'markup');
  }
};

export const setClass = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const elements: Array<HTMLElement> = Array.from(template?.querySelectorAll(selector));
  if (elements.length > 0) {
    elements.forEach((element: HTMLElement) => {
      const classes = element.getAttribute('class')?.split(' ').filter((className: string) => !className.startsWith(property.name));
      if (classes) {
        classes.push(`${property.name}${property.value}`);
        element.setAttribute('class', (classes.join(' ') ?? ''));
      }
    });
  }
};

export const setVoucherIconClass = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const elements: Array<HTMLElement> = Array.from(template?.querySelectorAll(selector));
  if (elements.length > 0) {
    elements.forEach((element: HTMLElement) => {
      const classes = element.getAttribute('class')?.split(' ').filter((className: string) => !className.startsWith(property.name));
      if (classes) {
        classes.push(`${property.name}${property.value}`);
        element.setAttribute('class', (classes.join(' ') ?? ''));
        const parentElement = getFirstParent(element, '.spm_generate_voucher_button');
        if (!classes.join(' ').match('spm_icon-hidden')) {
          parentElement.classList.add('spm_with_icon');
        } else {
          parentElement.classList.remove('spm_with_icon');
        }
      }
    });
  }
};

export const toggleClass = (selector: string, property: Property, options: any = null) => {
  const template = getTemplateIframeDocument();
  const elements: Array<HTMLElement> = Array.from(template?.querySelectorAll(selector));
  const value = typeof options === 'object' && options !== null && options.revert === true ? 'false' : 'true';
  if (elements.length > 0) {
    elements.forEach((element: HTMLElement) => {
      if (property.value === value) {
        element.classList.add(property.name);
      } else {
        element.classList.remove(property.name);
      }
    });
  }
};

export const setTagType = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  if (element) {
    element.outerHTML = `<${property.value}>${element.innerHTML}</${property.value}>`;
  }
};

export const setAnimated = (selector: string) => {
  const template = getTemplateIframeDocument();
  const elements: Array<HTMLElement> = Array.from(template?.querySelectorAll(selector));
  if (elements.length > 0) {
    elements.forEach((element: HTMLElement) => {
      element.classList.add('spm_animated');
    });
  }
};

export const setFacebookButtons = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  let buttonsContainer: HTMLElement | null = template?.querySelector(selector);

  if (!buttonsContainer) {
    // In case the widget doesn't have the container, we create it
    const regExpWidgetId = new RegExp('#spm_widget_[a-zA-Z0-9]+');
    const matches = regExpWidgetId.exec(selector);

    if (matches) {
      const widgetId = Array.from(matches)[0];
      const widget = template.querySelector(widgetId);

      if (widget) {
        const div = document.createElement('div');
        div.classList.add('buttons', 'fbButtons');
        widget.append(div);
        buttonsContainer = template?.querySelector(selector);
      }
    }
  }

  if (buttonsContainer) {
    buttonsContainer.innerHTML = '';
    const parentNode = buttonsContainer.closest('.spm_draggable_widget');

    if (parentNode) {
      parentNode.classList.remove('fb_widget_with_buttons');
    }

    property.value.forEach((button: FacebookMessengerButton) => {
      const placeholder: HTMLElement = document.createElement('div');
      const { newHtml } = generateTranslations(facebookButtonStructure(), { newElement: true });
      placeholder.innerHTML = newHtml;
      const newButton = placeholder.querySelector<HTMLElement>('.button');

      if (newButton) {
        if (button.dataRedirect !== '') {
          const dataRedirect = button.action === ActionTypeEnum.REDIRECT ? `base64:${btoa(button.dataRedirect)}` : `#redirect_${btoa(button.dataRedirect)}`;
          newButton.setAttribute('data-redirect', dataRedirect);
          setTranslationInState(newButton, 'attribute', 'data-redirect');
        }

        sanitizeTranslationMarkup(newButton).innerHTML = button.text;
        setTranslationInState(newButton, 'markup');

        if (buttonsContainer) {
          buttonsContainer.append(newButton);
        }
      }
    });

    if (property.value.length && parentNode) {
      parentNode.classList.add('fb_widget_with_buttons');
    }
  }
};

export const setFacebookMessengerSkin = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template?.querySelector(selector);

  if (element) {
    const parent: HTMLElement | null = element.parentElement;

    if (parent) {
      const image: HTMLElement | null = parent.querySelector('img');

      if (image) {
        const newSkin: string = property.value;
        const newUrl: string = (FacebookMessengerSkinsUrlPreview as { [key: string]: string })[newSkin.toUpperCase()];

        image.setAttribute('src', newUrl);
      }
    }
  }
};

export const calculateSmsLimits = (message: string): {limitFirst: number; limitMultiple: number} => {
  const regexpSpecialChars = new RegExp('[Þ°¨«»„…•–—´`]', 'g');

  let limitFirst;
  let limitMultiple;

  if (regexpSpecialChars.test(message)) {
    limitFirst = 70;
    limitMultiple = 67;
  } else {
    limitFirst = 160;
    limitMultiple = 153;
  }

  return { limitFirst, limitMultiple };
};

export const calculateSmsLength = (message: string): {nbMessages: number; nbCharactersMax: number; returnMessage: string} => {
  const { t } = useI18n();
  const availableVariables: Variable[] = VariablesList.allVariables;

  const maxMessages = 5;
  const { limitFirst, limitMultiple } = calculateSmsLimits(message);

  // On remplace toutes les variables pouvant contenir une URL par une URL raccourcie
  let body = message.replace(/((http|ftp)+(s)?:\/\/[^<>\s]+)|(CUSTOM_LINK)|(SITE_URL_W_HTTP)|(SITE_URL)/gi, 's6m.me/12345');

  availableVariables.forEach((variable: Variable) => {
    body = body.replace(new RegExp(variable.value, 'gi'), '');
  });

  let bodyLength = body.length;

  // On compte double certains caractères
  body = body.replace(new RegExp('[|^€{}\\[\\]\\~\\\\]', 'g'), '');
  bodyLength += bodyLength - body.length;

  let nbMessages = (bodyLength + 15 > limitFirst) ? Math.ceil((bodyLength + 15) / limitMultiple) : 1;
  if (nbMessages > maxMessages) {
    nbMessages = maxMessages;
  }

  // @TODO : corriger le bug de comptage (présent en prod également) lorsque des variables sont présentes dans le texte, afin de limiter la saisie dans le champ textarea

  const nbCharactersMax = nbMessages > 1 ? ((nbMessages * limitMultiple) - 15) : (limitFirst - 15);

  // On supprime du tableau des variables celles qui correspondent à des liens, vu que l'on connait la longueur finale de la chaine
  const availableVariablesWithoutLinks = availableVariables.filter((variable: Variable) => (!['CUSTOM_LINK', 'SITE_URL_W_HTTP', 'SITE_URL'].includes(variable.value)))
    .map((variable: Variable) => variable.value);

  const regexpVariables = new RegExp(`(${availableVariablesWithoutLinks.join(')|(')})`, 'g');

  let returnMessage;
  if (regexpVariables.test(message)) {
    // On a d'autres variables dans le message, donc on va afficher le bon message
    returnMessage = t('templateBuilder.informations.smsInformationMessageVariables', {
      nbMessages,
      smsUnitPrice: '0.07',
    });
  } else {
    // On a pas d'autre variable, on va calculer la longueur du message
    returnMessage = t('templateBuilder.informations.smsInformationMessageStandard', {
      nbCharacters: bodyLength,
      nbCharactersMax,
      nbMessages,
      smsUnitPrice: '0.07',
    });
  }

  return { nbMessages, nbCharactersMax, returnMessage };
};

export const getCssStyles = (type: string, beautify = false): string => {
  const template = getTemplateIframeDocument();

  if (!template) {
    return '';
  }

  const selector = `style[data-spm-styles="${type}"]`;

  const css: HTMLElement | null = template.querySelector(selector);

  if (!css) {
    return '';
  }

  return beautify ? beautifier.css(css.innerHTML.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '')) : css.innerHTML.replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, '');
};

export const setCssStyles = (type: string, value: string, sectionId: string | undefined): void => {
  const template = getTemplateIframeDocument();

  if (template) {
    const selector = `style[data-spm-styles="${type}"]`;
    let css = template.querySelector(selector) as HTMLStyleElement;

    if (!css) {
      const head = template.querySelector('head');

      if (head) {
        css = document.createElement('style');
        css.setAttribute('data-spm-styles', type);
        if (sectionId) {
          css.setAttribute('data-spm-section', sectionId);
        }
        head.appendChild(css);
      }
    }

    if (css) {
      css.innerText = beautifier.css(value).replace(/(\r\n|\n|\r|<br>)/gm, '');
    }
  }
};

export const recalculateLineHeightValue = (selector: string, property: Property) => {
  if (undefined !== property.value) {
    const { value } = property;
    if (value) {
      const elements = getTemplateIframeDocument()?.querySelectorAll(selector);
      const dynamicStyles = getDynamicStylesForSelector(selector);

      let lineHeight: string | undefined;
      if (Object.prototype.hasOwnProperty.call(dynamicStyles, 'line-height') && dynamicStyles['line-height']) {
        lineHeight = dynamicStyles['line-height'];
      }

      let baseFontSize: string | undefined;
      if (Object.prototype.hasOwnProperty.call(dynamicStyles, 'font-size') && dynamicStyles['font-size']) {
        baseFontSize = dynamicStyles['font-size'];
      }

      const authorizedLineHeights: { label: string; value: string }[] = LINE_HEIGHT_POSSIBLE_VALUES;
      const authorizedLineHeightsList: number[] = authorizedLineHeights.reduce((acc: number[], lineHeightValue) => {
        acc.push(parseFloat(lineHeightValue.value));
        return acc;
      }, []);
      let newLineHeight;
      if (lineHeight && lineHeight !== '0px' && elements && elements.length > 0) {
        elements.forEach((element) => {
          const htmlElement = element as HTMLElement;
          const css = window.getComputedStyle(htmlElement);
          const fontSize = css.getPropertyValue('font-size');

          if (fontSize && fontSize !== '0px') {
            if (!baseFontSize) {
              baseFontSize = fontSize;
            }

            if (lineHeight) {
              if (/^\d+\.?\d*$/.test(lineHeight)) {
                newLineHeight = ((parseFloat(lineHeight) * parseFloat(fontSize)) * 100) / 100;
              } else if (lineHeight.match(/%$/)) {
                newLineHeight = (((parseFloat(lineHeight) / 100) * parseFloat(fontSize)) * 100) / 100;
              } else {
                const coefficient = ((parseFloat(lineHeight) / parseFloat(baseFontSize)) * 100) / 100;
                newLineHeight = parseFloat(fontSize) * coefficient;
              }
              newLineHeight = Math.round(newLineHeight);
              htmlElement.style.lineHeight = `${newLineHeight}px`;
            }
          } else if (fontSize && fontSize === '0px') {
            htmlElement.style.lineHeight = '0px';
          }
        });
      }

      if (lineHeight && baseFontSize) {
        if (/^\d+\.?\d*$/.test(lineHeight)) {
          newLineHeight = ((parseFloat(lineHeight) * parseFloat(value)) * 100) / 100;
        } else if (lineHeight.match(/%$/)) {
          newLineHeight = (((parseFloat(lineHeight) / 100) * parseFloat(value)) * 100) / 100;
        } else {
          const coefficient = ((parseFloat(lineHeight) / parseFloat(baseFontSize)) * 100) / 100;
          newLineHeight = parseFloat(value) * coefficient;
        }
        newLineHeight = Math.round(newLineHeight);
        addOrUpdateDynamicStyle(selector, { 'line-height': `${newLineHeight}px` });
      }
    }
  }
};

const removeComments = (el: any, forColumn = false) => {
  if (!forColumn) {
    el.childNodes.forEach((node: HTMLElement) => {
      if (node.nodeType === Node.COMMENT_NODE) {
        if (node.textContent && node.textContent.trim().match(/\[if gte mso 9\]>(<\/v:textbox>|<v:rect xmlns:v=)[\s\S]*?<!\[endif\]/g)) {
          if (node.parentNode) {
            node.parentNode.removeChild(node);
          }
        }
      }
    });
  } else {
    if (el.previousSibling?.nodeType === Node.COMMENT_NODE) {
      if (el.previousSibling.textContent && el.previousSibling.textContent.trim().match(/\[if gte mso 9\]>(<v:textbox>|<v:rect xmlns:v=)[\s\S]*?<!\[endif\]/g)) {
        if (el.parentNode) {
          el.parentNode.removeChild(el.previousSibling);
        }
      }
    }
    if (el.nextSibling?.nodeType === Node.COMMENT_NODE) {
      if (el.nextSibling.textContent && el.nextSibling.textContent.trim().match(/\[if gte mso 9\]>(<\/v:textbox>|<\/v:rect xmlns:v=)[\s\S]*?<!\[endif\]/g)) {
        if (el.parentNode) {
          el.parentNode.removeChild(el.nextSibling);
        }
      }
    }
  }
};

const insertOutlookCompatibility = (elemennt: HTMLElement, width: string, url: string, startPosition: InsertPosition, endPosition: InsertPosition) => {
  const conditionalComment = '<!--[if gte mso 9]>';
  const rectElement = `<v:rect xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false" style="${width}">`;
  const fillElement = `<v:fill type="tile" src="${url}" />`;
  const textboxElement = '<v:textbox style="mso-fit-shape-to-text:true" inset="0,0,0,0">';
  const closingComment = '<![endif]-->';

  elemennt.insertAdjacentHTML(
    startPosition,
    `${conditionalComment}${rectElement}${fillElement}${textboxElement}${closingComment}`,
  );
  elemennt.insertAdjacentHTML(
    endPosition,
    '<!--[if gte mso 9]></v:textbox></v:rect><![endif]-->',
  );
};

export const setBackgroundImage = (selector: string, property: Property, forColumn = false) => {
  if (isEmailTemplate(TemplateEditorState.template?.type)) {
    const element = getTemplateIframeDocument()?.querySelector(selector);
    if (element) {
      const htmlElement = element as HTMLElement;
      let canvasWidth;
      if (selector.match('.container')) {
        const css = window.getComputedStyle(htmlElement);
        const width = css.getPropertyValue('width');
        canvasWidth = `width:${width}`;
      } else {
        canvasWidth = 'mso-width-percent:1000;';
      }
      const url = property.value.match(/url\("([^"]+)"\)/) ? property.value.match(/url\("([^"]+)"\)/)[1] : '';

      const bgOutlookSelector = `${selector} > tbody > tr`;
      const bgElement = getTemplateIframeDocument()?.querySelector(bgOutlookSelector);
      if (bgElement) {
        // Remove last outlook compatibility comment
        removeComments(bgElement);
        if (selector.match('.container')) {
          const parentBgElement = getTemplateIframeDocument()?.querySelector(`${selector.replace('.container', '')} > tbody > tr`);
          removeComments(parentBgElement);

          if (parentBgElement) {
            parentBgElement.removeAttribute('background');
            removeDynamicStyle(`${selector.replace(' .container', '')}`, { backgroundImage: '' });
          }

          const parentTable = getTemplateIframeDocument()?.querySelector(`${selector.replace('.container', '')}`);
          if (parentTable) {
            parentTable.removeAttribute('background');
          }
        } else {
          const containerElement = getTemplateIframeDocument()?.querySelector(`${selector} .container > tbody > tr`);
          if (containerElement) {
            removeComments(containerElement);
          }

          const parentTable = getTemplateIframeDocument()?.querySelector(`${selector} .container`);
          if (parentTable) {
            parentTable.removeAttribute('background');
            removeDynamicStyle(`${selector} .container`, { backgroundImage: '' });
          }
        }

        insertOutlookCompatibility(bgElement as HTMLElement, canvasWidth, url, 'afterbegin', 'beforeend');
      } else {
        // Remove last outlook compatibility comment
        removeComments(element, forColumn);

        if (element) {
          element.removeAttribute('background');
        }

        if (forColumn) {
          insertOutlookCompatibility(element as HTMLElement, canvasWidth, url, 'beforebegin', 'afterend');
        } else {
          insertOutlookCompatibility(element as HTMLElement, canvasWidth, url, 'afterbegin', 'beforeend');
        }
      }
      if (property.value === 'none') {
        element.removeAttribute('background');
        if (bgElement) {
          removeComments(bgElement);
        } else {
          removeComments(element, forColumn);
        }
      } else {
        element.setAttribute('background', url);
      }
    }
  }
  if (property.value === 'none') {
    if (isEmailTemplate(TemplateEditorState.template?.type)) {
      removeDynamicStyle(selector, { backgroundImage: '', background: '' });
    } else {
      removeDynamicStyle(selector, { backgroundImage: '' });
    }
  } else {
    setCssProperty(selector, property);
  }
};

export const setBackgroundImageProperty = (selector: string, property: Property) => {
  if (property.value) {
    setCssProperty(selector, property);
  } else {
    removeDynamicStyle(selector, { [property.name]: '' });
  }
};

export const setHtmlContent = (selector: string, property: Property, widgetId: string) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template?.querySelector(selector);
  if (element && undefined !== property.value) {
    const clonedElement = element.cloneNode(true) as HTMLElement;
    clonedElement.innerHTML = property.value;
    const { newHtml } = generateTranslations(
      clonedElement.outerHTML,
      {
        newElement: true,
        translateVariables: false,
        ignoreSpecificItems: true,
      },
    );

    const div = document.createElement('div');
    div.innerHTML = newHtml;

    const fragment = document.createDocumentFragment();

    while (div.firstChild) {
      // appendChild déplace le firstChild du tempContainer au fragment
      fragment.appendChild(div.firstChild);
    }

    let newInnerHTML = '';
    fragment.childNodes.forEach((node) => {
      if (node instanceof HTMLElement) {
        newInnerHTML += node.innerHTML;
      }
    });

    // eslint-disable-next-line no-param-reassign
    property.value = newInnerHTML;

    // This setter can be involved by translation
    element.innerHTML = property.value;

    // Update translation in state
    const newDiv = document.createElement('div');
    newDiv.innerHTML = property.value;

    const defaultLanguage: string = UserState.activeShop?.langs.filter((lang) => lang.default).map((lang) => lang.id)[0] ?? 'fr';
    const translationIds = property.value.match(/[a-zA-Z-]+="LANG_[a-zA-Z0-9.]+"/gm);
    if (translationIds && translationIds.length) {
      translationIds.forEach((translationId: string) => {
        const attributeRaw = translationId.replace(/"/gm, '');
        const [attributeName, attributeValue] = attributeRaw.split('=');
        if (attributeName && attributeValue) {
          const matchedElement = newDiv.querySelector(`[${attributeName}="${attributeValue}"]`);
          if (matchedElement) {
            if (attributeName === 'id') {
              replaceTranslationInState(attributeValue.replace('LANG_', ''), defaultLanguage, matchedElement.innerHTML);
            } else {
              const attributeToGet = attributeName.replace(`${TRANSLATION_ATTRIBUTE}-`, '');
              const newValue = matchedElement.getAttribute(attributeToGet);
              if (newValue !== undefined || newValue !== null) {
                replaceTranslationInState(attributeValue.replace('LANG_', ''), defaultLanguage, newValue as string);
              }
            }
          }
        }
      });
    }

    updateSectionsInState();
    removeUnusedTranslations();

    if (htmlHasSmartlist(element.innerHTML)) {
      addSmartListOriginalCode(widgetId, element.innerHTML);
    }
  }
};
