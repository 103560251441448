/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-cycle
import { store } from '@/store';

import Sortable from 'sortablejs';

import {
  ActiveLiveEditorItemData, HistoryType, TemplateStructureEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  addElementTypeByLang,
  addOrRemoveJustImageClass,
  insertEmbedTemplateInSelectedElement, refreshSectionsConfig,
  resetIframe,
  setTemplateConfigurationKeyInState,
  TemplateEditorState as state,
  togglePanel,
  updateSectionIdOfTranslationsInState, updateSectionsInState,
  setSelectedTranslationId,
  setShowConfigurationPanelCallback,
  getTranslationIdFromImportedTemplateElement, setPerfectSizeForImage, TemplateEditorState,
} from '@/composables/template-editor/TemplateEditor';
// eslint-disable-next-line import/no-cycle
import { RAW_PLACEHOLDER_STRUCTURE_MAP } from '@/components/template-builder/utils/raw-html-templates';
import { getParents } from '@/helpers';
// eslint-disable-next-line import/no-cycle
import { removeColumn } from '@/components/template-builder/utils/action-buttons-handlers';
// eslint-disable-next-line import/no-cycle
import { addActiveItemElementDecorations, removeActiveItemElementDecorations } from '@/components/template-builder/utils/active-item-builder';
import { ref } from 'vue';
// eslint-disable-next-line import/no-cycle
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';

import { Maybe } from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import {
  addOrUpdateDynamicStyle,
  getTemplateColumns,
  getTemplateSections,
  getTemplateWidgets, moveDynamicStyleFromSectionToAnother,
} from './parser';
// eslint-disable-next-line import/no-cycle
import {
  getTemplateIframeDocument,
  getTemplateParentTypeByType,
  getWidgetType,
  getTranslationIdFromWidget, isEmailTemplate,
} from './helpers';
import {
  ACTIONS_BUTTON_GROUP,
  ANIMATION_SPEED,
  BUILDER_ELEMENT_ACTIVE_CLASS,
  BUILDER_ELEMENT_HOVER_CLASS,
  BUILDER_EMBED_CONTAINER_HOVER_CLASS,
  BUILDER_EMBED_TEMPLATE_CONTAINERS,
  BUILDER_SORTABLE_CHOSEN_CLASS,
  BUILDER_SORTABLE_DRAG_CLASS,
  BUILDER_SORTABLE_GHOST_CLASS,
  BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS,
  COLUMN_WITH_PLACEHOLDER_CLASS,
  TEMPLATE_SECTION_CLASS,
  TEMPLATE_SECTION_CONTENT_ID_SUFFIX,
  TEMPLATE_SECTION_IDENTIFIER,
  TEMPLATE_WIDGET_IDENTIFIER,
  WIDGET_DRAGGABLE_CLASS, WIDGET_DRAGGABLE_IDENTIFIER,
  WIDGET_DROPPABLE_CLASS, WIDGET_DROPPABLE_IDENTIFIER,
  WIDGETS_PLACEHOLDER_IDENTIFIER,
} from './constants';

const setSectionHoverStyles = (section: HTMLElement) => {
  section.classList.add(BUILDER_ELEMENT_HOVER_CLASS);
};

const resetSectionHoverStyles = (section: HTMLElement) => {
  section.classList.remove(BUILDER_ELEMENT_HOVER_CLASS);
};

const columnsButtonClickListener = (event: any) => {
  event.preventDefault();
  event.stopPropagation();
  let column = event.target;
  if (!column.classList || !column.classList.contains(WIDGET_DROPPABLE_IDENTIFIER)) {
    column = getParents(column, WIDGET_DROPPABLE_CLASS);

    if (column && column.length) {
      [column] = column;
    }
  }

  if (column.classList.contains(WIDGET_DROPPABLE_IDENTIFIER)) {
    removeColumn(event, column);
  }
};

const columnsMouseEnterListener = (event: any) => {
  event.preventDefault();
  event.stopPropagation();

  let column = event.target;
  if (!column.classList || !column.classList.contains(WIDGET_DROPPABLE_IDENTIFIER)) {
    column = getParents(column, WIDGET_DROPPABLE_CLASS);

    if (column && column.length) {
      [column] = column;
    }
  }

  if (column.classList.contains(WIDGET_DROPPABLE_IDENTIFIER)) {
    setSectionHoverStyles(column);

    if (!store.getters['liveEditor/getSelectedStructure']) {
      // Display section's actions toolbar
      const activeItemData: ActiveLiveEditorItemData = {
        selector: `#${column.getAttribute('id')}` ?? '',
        type: TemplateStructureEnum.COLUMN,
      };

      addActiveItemElementDecorations(activeItemData);
    }
  }
};

const columnsMouseLeaveListener = (event: any) => {
  event.preventDefault();
  event.stopPropagation();

  let column = event.target;
  if (!column.classList || !column.classList.contains(WIDGET_DROPPABLE_IDENTIFIER)) {
    column = getParents(column, WIDGET_DROPPABLE_CLASS);

    if (column && column.length) {
      [column] = column;
    }
  }

  if (column.classList.contains(WIDGET_DROPPABLE_IDENTIFIER)) {
    event.target.classList.remove(BUILDER_ELEMENT_HOVER_CLASS);

    if (!store.getters['liveEditor/getSelectedStructure']) {
      removeActiveItemElementDecorations();
    }
  }
};

const columsClickListener = (event: any) => {
  event.preventDefault();
  event.stopPropagation();

  let column = event.target;
  if (!column.classList || !column.classList.contains(WIDGET_DROPPABLE_IDENTIFIER)) {
    column = getParents(column, WIDGET_DROPPABLE_CLASS);

    if (column && column.length) {
      [column] = column;
    }
  }

  if (column.classList.contains(WIDGET_DROPPABLE_IDENTIFIER)) {
    togglePanel('configurationPanel', event).then(() => {
      removeActiveItemElementDecorations();
      store.dispatch('liveEditor/prepareStructureConfiguration', {
        type: TemplateStructureEnum.COLUMN,
        identifier: column.id,
      });
    });
  }
};

export const addColumnsListeners = (columns?: HTMLElement[]) => {
  const columnsArray = (columns || getTemplateColumns());
  columnsArray.forEach((column: any) => {
    // We check if the column has children. If false, we create a placeholder
    if (column.children.length === 0) {
      const div = document.createElement('div');
      div.innerHTML = RAW_PLACEHOLDER_STRUCTURE_MAP[getTemplateParentTypeByType(state.template?.type)]();
      const placeholder = div.getElementsByClassName(WIDGETS_PLACEHOLDER_IDENTIFIER)[0];
      column.appendChild(placeholder);
      column.classList.add(COLUMN_WITH_PLACEHOLDER_CLASS);
    } else if (column.querySelector(`.${WIDGETS_PLACEHOLDER_IDENTIFIER}`)) {
      column.classList.add(COLUMN_WITH_PLACEHOLDER_CLASS);
    } else {
      column.classList.remove(COLUMN_WITH_PLACEHOLDER_CLASS);
    }

    // For each placeholder found, we add the ability to remove the column.
    if (column.classList.contains(COLUMN_WITH_PLACEHOLDER_CLASS)) {
      if (getTemplateColumns().length === 1) {
        // If there is only one column in the template, we remove the delete button
        // eslint-disable-next-line no-unused-expressions
        column.querySelector('.button-delete')?.remove();
      } else {
        const button = column.querySelector('svg');
        if (button) {
          button.removeEventListener('click', columnsButtonClickListener);
          button.addEventListener('click', columnsButtonClickListener);
        }
      }
    }

    if (column.classList.contains(COLUMN_WITH_PLACEHOLDER_CLASS)) {
      if (typeof column.haveEventListener === 'undefined') {
        column.haveEventListener = true;
        column.removeEventListener('mouseenter', columnsMouseEnterListener);
        column.addEventListener('mouseenter', columnsMouseEnterListener);

        column.removeEventListener('mouseleave', columnsMouseLeaveListener);
        column.addEventListener('mouseleave', columnsMouseLeaveListener);

        column.removeEventListener('click', columsClickListener);
        column.addEventListener('click', columsClickListener);
      }

      addElementTypeByLang(column);
    } else {
      column.removeEventListener('mouseenter', columnsMouseEnterListener);
      column.removeEventListener('mouseleave', columnsMouseLeaveListener);
      column.removeEventListener('click', columsClickListener);
    }
  });
};

const sectionsMouseEnterListener = (event: any) => {
  let section = event.target;
  if (!section.classList || !section.classList.contains(TEMPLATE_SECTION_CLASS)) {
    section = getParents(event.target, `.${TEMPLATE_SECTION_CLASS}`);

    if (section && section.length) {
      [section] = section;
    }
  }

  if (section.classList.contains(TEMPLATE_SECTION_CLASS) && !section.classList.contains(BUILDER_ELEMENT_ACTIVE_CLASS)) {
    setSectionHoverStyles(section);

    if (!store.getters['liveEditor/getSelectedStructure']) {
      // Display section's actions toolbar
      const activeItemData: ActiveLiveEditorItemData = {
        selector: `#${section.getAttribute('id')}` ?? '',
        type: TemplateStructureEnum.SECTION,
      };

      addActiveItemElementDecorations(activeItemData);
    }
  }
};

const sectionsMouseMoveListener = (event: any) => {
  // We check if the target is inside a widget. If true, we remove hover class, else, we add hover class
  const widget = getParents(event.target, WIDGET_DRAGGABLE_CLASS);
  let column = event.target;
  if (!column.classList || !column.classList.contains(WIDGET_DROPPABLE_IDENTIFIER)) {
    column = getParents(column, WIDGET_DROPPABLE_CLASS);

    if (column && column.length) {
      [column] = column;
    }
  }

  let section = event.target;
  if (!section.classList || !section.classList.contains(TEMPLATE_SECTION_CLASS)) {
    section = getParents(section, `.${TEMPLATE_SECTION_CLASS}`);

    if (section && section.length) {
      [section] = section;
    }
  }

  if ((widget && widget.length) || (column && column.classList && column.classList.contains(WIDGET_DROPPABLE_IDENTIFIER))) {
    resetSectionHoverStyles(section);
  } else {
    setSectionHoverStyles(section);

    if (!store.getters['liveEditor/getSelectedStructure']) {
      // Display section's actions toolbar
      const activeItemData: ActiveLiveEditorItemData = {
        selector: `#${section.getAttribute('id')}` ?? '',
        type: TemplateStructureEnum.SECTION,
      };
      addActiveItemElementDecorations(activeItemData);
    }
  }
};

const sectionsMouseLeaveListener = (event: any) => {
  let section = event.target;
  if (!section.classList || !section.classList.contains(TEMPLATE_SECTION_CLASS)) {
    section = getParents(event.target, `.${TEMPLATE_SECTION_CLASS}`);

    if (section && section.length) {
      [section] = section;
    }
  }

  resetSectionHoverStyles(section);

  if (!store.getters['liveEditor/getSelectedStructure']) {
    removeActiveItemElementDecorations();
  }

  const template = getTemplateIframeDocument();
  const tooltipElement: HTMLElement | null = template.querySelector(`#${section.id.replaceAll('_content', '')} .tooltip`);
  if (tooltipElement && tooltipElement.style.visibility !== 'hidden') {
    tooltipElement.style.visibility = 'hidden';
  }
};

const sectionsClickListener = (event: any) => {
  event.stopPropagation();
  if (event && !(event.target as HTMLElement).id.match(new RegExp('load-section')) && !state.leftToolbar.show.translationPanel) {
    togglePanel('configurationPanel', event).then(() => {
      let section = event.target;
      if (!section.classList.contains(TEMPLATE_SECTION_CLASS)) {
        section = getParents(event.target, `.${TEMPLATE_SECTION_CLASS}`);

        if (section && section.length) {
          [section] = section;
        }
      }

      removeActiveItemElementDecorations();

      if (section.classList && section.classList.contains(TEMPLATE_SECTION_CLASS)) {
        // We prepare structure configuration and open the configuration panel only if we click inside a section, not the load-section button
        store.dispatch('liveEditor/prepareStructureConfiguration', {
          type: TemplateStructureEnum.SECTION,
          identifier: section.id.replaceAll(TEMPLATE_SECTION_CONTENT_ID_SUFFIX, ''),
        });
      }
    });
  }
};

export const addSectionsListeners = (sections?: HTMLElement[]) => {
  (sections || getTemplateSections()).forEach((section: any) => {
    if (typeof section.haveEventListener === 'undefined') {
      section.haveEventListener = true;

      section.removeEventListener('mouseenter', sectionsMouseEnterListener);
      section.addEventListener('mouseenter', sectionsMouseEnterListener);

      section.removeEventListener('mousemove', sectionsMouseMoveListener);
      section.addEventListener('mousemove', sectionsMouseMoveListener);

      section.removeEventListener('mouseleave', sectionsMouseLeaveListener);
      section.addEventListener('mouseleave', sectionsMouseLeaveListener);

      section.removeEventListener('click', sectionsClickListener);
      section.addEventListener('click', sectionsClickListener);
    }
    addElementTypeByLang(section);
  });
};

const widgetsMouseEnterEvent = (event: any) => {
  const chooseEmbedPosition = ref(store.getters['liveEditor/getActiveEmbedPositionChooser']);
  if (!chooseEmbedPosition.value && !event.target.classList.contains(BUILDER_ELEMENT_HOVER_CLASS)) {
    event.target.classList.add(BUILDER_ELEMENT_HOVER_CLASS);

    // Remove section hover class if hovered element is a widget
    const section = getParents(event.target, `.${TEMPLATE_SECTION_CLASS}`);

    if (section && section.length) {
      resetSectionHoverStyles(section[0]);

      if (!store.getters['liveEditor/getSelectedStructure']) {
        removeActiveItemElementDecorations();
      }
    }

    if (!store.getters['liveEditor/getSelectedStructure']) {
      // Display widget's actions toolbar
      const activeItemData: ActiveLiveEditorItemData = {
        selector: `#${event.target.getAttribute('id')}` ?? '',
        type: TemplateStructureEnum.WIDGET,
      };
      addActiveItemElementDecorations(activeItemData);
    }
  }
};

const widgetsMouseLeaveListener = (event: any) => {
  const chooseEmbedPosition = ref(store.getters['liveEditor/getActiveEmbedPositionChooser']);

  if (!chooseEmbedPosition.value) {
    event.target.classList.remove(BUILDER_ELEMENT_HOVER_CLASS);

    if (!store.getters['liveEditor/getSelectedStructure']) {
      removeActiveItemElementDecorations();
    }
  }
};

const widgetsClickListener = (event: any) => {
  // Check if target is inside widget's toolbar.
  // If true, we let the action do. Else, we prevent the action and stop the propagation
  const parentOfTarget = getParents(event.target, `.${ACTIONS_BUTTON_GROUP}`);

  if (!parentOfTarget || parentOfTarget.length === 0) {
    event.preventDefault();
    event.stopPropagation();

    const chooseEmbedPosition = ref(store.getters['liveEditor/getActiveEmbedPositionChooser']);

    if (!chooseEmbedPosition.value) {
      let widget = event.target;
      if (!widget.classList || !widget.classList.contains(WIDGET_DRAGGABLE_IDENTIFIER)) {
        widget = getParents(event.target, WIDGET_DRAGGABLE_CLASS);

        if (widget && widget.length) {
          [widget] = widget;
        }
      }

      const showConfigurationPanel = () => {
        // If left panel is open, we close it
        togglePanel('configurationPanel', event)
          .then(() => {
            removeActiveItemElementDecorations();
            if (widget && widget.classList && widget.classList.contains(WIDGET_DRAGGABLE_IDENTIFIER)) {
              store.dispatch('liveEditor/prepareStructureConfiguration', {
                type: TemplateStructureEnum.WIDGET,
                identifier: widget.id,
                widgetType: getWidgetType(widget.getAttribute('data-widgettype')),
              });
            }
          });
      };

      if (state.leftToolbar.show.translationPanel) {
        const translationId = getTranslationIdFromImportedTemplateElement(event.target);
        if (translationId) {
          setSelectedTranslationId(translationId);
        }
        setShowConfigurationPanelCallback(showConfigurationPanel);
      } else {
        showConfigurationPanel();
      }
    }
  }
};

export const addWidgetsListeners = (widgets?: HTMLElement[]) => {
  (widgets || getTemplateWidgets()).forEach((widget: any) => {
    if (typeof widget.haveEventListener === 'undefined') {
      widget.haveEventListener = true;
      widget.addEventListener('mouseenter', (event: any) => {
        widgetsMouseEnterEvent(event);
      });
      widget.addEventListener('mouseleave', (event: any) => {
        widgetsMouseLeaveListener(event);
      });

      widget.addEventListener('click', (event: any) => {
        widgetsClickListener(event);
      }, true);
    }
    addElementTypeByLang(widget);
  });
};

// Collection pour stocker les instances de Sortable
export const sortableGlobalInstances: Sortable[] = [];
export const sortableIframeInstances: Sortable[] = [];

export const destroyIframeSortables = () => {
  sortableIframeInstances.forEach((instance) => instance.destroy());
  sortableIframeInstances.length = 0; // Vide le tableau
};

export const destroyAllSortables = () => {
  sortableIframeInstances.forEach((instance) => instance.destroy());
  sortableIframeInstances.length = 0; // Vide le tableau
  sortableGlobalInstances.forEach((instance) => instance.destroy());
  sortableGlobalInstances.length = 0; // Vide le tableau
};

export const addDragAndDropSectionsListener = () => {
  const sections = getTemplateSections();
  const sectionsGroup: Maybe<HTMLElement & { isSortable?: boolean }> = sections[0]?.parentElement;
  if (sectionsGroup) {
    const disabled = ref(store.getters['liveEditor/getActiveEmbedPositionChooser']);
    if (typeof sectionsGroup.isSortable === 'undefined') {
      sectionsGroup.isSortable = true;
      const sortable = Sortable.create(sectionsGroup, {
        group: { name: TemplateStructureEnum.SECTION, pull: [TemplateStructureEnum.SECTION], put: [TemplateStructureEnum.SECTION] },
        animation: ANIMATION_SPEED,
        handle: `${TEMPLATE_SECTION_IDENTIFIER}`,
        dragClass: BUILDER_SORTABLE_DRAG_CLASS,
        chosenClass: BUILDER_SORTABLE_CHOSEN_CLASS,
        ghostClass: BUILDER_SORTABLE_GHOST_CLASS,
        disabled: disabled.value,
        swapThreshold: 0.2,
        onStart() {
          checkIfSavePointNeeded();
        },
        onEnd(evt) {
          if (evt.oldIndex !== evt.newIndex) {
            // Update state
            refreshSectionsConfig();
            updateSectionsInState(false);
            createHistory(HistoryType.SECTIONS_ORDER_CHANGE);
          }
        },
      });
      sortableIframeInstances.push(sortable);
    }
  }
};

export const addDragAndDropWidgetsListener = () => {
  const template = getTemplateIframeDocument();

  if (template) {
    const disabled = ref(store.getters['liveEditor/getActiveEmbedPositionChooser']);
    const widgetGroups = Array.from(template.querySelectorAll(WIDGET_DROPPABLE_CLASS));
    widgetGroups.forEach((groupWrapper: any) => {
      if (typeof groupWrapper.isSortable === 'undefined') {
        groupWrapper.isSortable = true;
        const sortable = Sortable.create(groupWrapper as HTMLElement, {
          group: { name: TemplateStructureEnum.WIDGET, pull: [TemplateStructureEnum.WIDGET], put: [TemplateStructureEnum.WIDGET] },
          animation: ANIMATION_SPEED,
          handle: `${TEMPLATE_WIDGET_IDENTIFIER}`,
          dragClass: BUILDER_SORTABLE_DRAG_CLASS,
          chosenClass: BUILDER_SORTABLE_CHOSEN_CLASS,
          ghostClass: BUILDER_SORTABLE_GHOST_CLASS,
          disabled: disabled.value,
          swapThreshold: 0.2,
          onStart() {
            checkIfSavePointNeeded();
          },
          onMove(evt) {
            Array.from(template.querySelectorAll(`.${BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS}`) ?? [])
              .forEach((element) => element.classList.remove(BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS));

            const target = evt.related;
            if (target.classList.contains(WIDGETS_PLACEHOLDER_IDENTIFIER)) {
              evt.dragged.classList.add('spm_hide_on_builder');
              evt.dragged.classList.remove('spm_widgets_on_builder');
              const parent = target.parentElement;
              if (parent) {
                parent.classList.add(BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS);
              }
            } else {
              evt.dragged.classList.remove('spm_hide_on_builder');
              evt.dragged.classList.add('spm_widgets_on_builder');
            }
          },
          onUnchoose() {
            Array.from(template.querySelectorAll(`.${BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS}`) ?? [])
              .forEach((element) => element.classList.remove(BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS));

            Array.from(template.querySelectorAll('.spm_widgets_on_builder') ?? [])
              .forEach((element) => element.classList.remove('spm_widgets_on_builder'));
          },
          onEnd(evt) {
            if (evt.from !== evt.to || evt.oldIndex !== evt.newIndex) {
              // Remove class to hide the widget during the drag into a column with placeholder
              evt.item.classList.remove('spm_hide_on_builder');
              evt.item.classList.remove('spm_widgets_on_builder');

              const destinationColumn = evt.to;

              // If destination column contains placeholder class, we remove it
              if (destinationColumn.classList.contains(COLUMN_WITH_PLACEHOLDER_CLASS)
                || destinationColumn.classList.contains('spm_widgets_on_builder')) {
                // Remove class and placeholder
                destinationColumn.classList.remove(COLUMN_WITH_PLACEHOLDER_CLASS);
                destinationColumn.classList.remove('spm_widgets_on_builder');

                // If destination column contains a placeholder for widget, we remove it
                if (destinationColumn.querySelector(`.${WIDGETS_PLACEHOLDER_IDENTIFIER}`)) {
                  // eslint-disable-next-line no-unused-expressions
                  destinationColumn.querySelector(`.${WIDGETS_PLACEHOLDER_IDENTIFIER}`)?.remove();
                }
              }

              // We check if the widget is still in the same section, otherwise we modify its translations to insert the new section ID
              const [parentSectionFrom] = getParents(evt.from, `.${TEMPLATE_SECTION_CLASS}`);
              const [parentSectionTo] = getParents(evt.to, `.${TEMPLATE_SECTION_CLASS}`);

              const sectionFromId = parentSectionFrom?.getAttribute('data-spmelementid');
              const sectionToId = parentSectionTo?.getAttribute('data-spmelementid');

              if (sectionFromId && sectionToId && sectionFromId !== sectionToId) {
                // We get all translation keys in widget to update their section's ID in state
                const widgetHtml = evt.item.outerHTML;
                const regExpTranslations = new RegExp('LANG_[a-zA-Z0-9.]+', 'g');

                // Matches all translation keys inside widget
                const matches = widgetHtml.matchAll(regExpTranslations);

                if (matches) {
                  const keysList: string[] = [];

                  // Loop on each translation key to store in array
                  Array.from(matches, (m) => m[0]).forEach((match) => {
                    const key = match.split('.')[0].replace('LANG_', '');
                    keysList.push(key);
                  });

                  // If we have keys to replace, we loop on translations in state to replace section's ID
                  updateSectionIdOfTranslationsInState(keysList, sectionToId);
                }
                // Moves widget styles from one section to another in the document.
                moveDynamicStyleFromSectionToAnother(`#${evt.item.getAttribute('id')}`, sectionFromId, sectionToId);
              }

              // We get the HTML code of the selected widget
              const widgetType = evt.item.getAttribute('data-widgettype');
              if (widgetType) {
                if (widgetType === 'spm_widget_image') {
                  const image = evt.item.querySelector('img') as HTMLImageElement;
                  // hide image for parent calculation
                  image.classList.add('spm_hide_on_builder');
                  const parent = getParents(evt.item.querySelector('img'), WIDGET_DRAGGABLE_CLASS);
                  const currentMaxValue = parent && parent.length > 0 ? parent[0]?.offsetWidth : null;
                  // show image after parent calculation
                  image.classList.remove('spm_hide_on_builder');
                  // Resize is necessary ?
                  if ((image && currentMaxValue && (image.clientWidth > currentMaxValue || image.clientWidth > image.naturalWidth)) || (image && !currentMaxValue)) {
                    if (isEmailTemplate(TemplateEditorState.template?.type)) {
                      setPerfectSizeForImage(image, '100%');
                    } else {
                      addOrUpdateDynamicStyle(`#${evt.item.getAttribute('id')} img`, { 'max-width': '100%' });
                      image.setAttribute('width', '100%');
                    }
                  }
                }
              }

              // Update state
              updateSectionsInState(false);

              createHistory(HistoryType.WIDGET_ORDER_CHANGE);

              addColumnsListeners();
              addDragAndDropWidgetsListener();
            }
          },
        });
        sortableIframeInstances.push(sortable);
      }
    });
  }
};

export const addDragAndDropLinesListener = () => {
  const template = getTemplateIframeDocument();

  if (template) {
    const linesGroups = Array.from(template.querySelectorAll('.spm_droppable_row'));
    linesGroups.forEach((groupWrapper: any) => {
      if (typeof groupWrapper.isSortable === 'undefined') {
        groupWrapper.isSortable = true;
        const sortable = Sortable.create(groupWrapper as HTMLElement, {
          sort: false,
          group: { name: `${TemplateStructureEnum.LINE}_builder`, pull: false, put: [TemplateStructureEnum.LINE] },
          animation: ANIMATION_SPEED,
        });
        sortableIframeInstances.push(sortable);
      }
    });
  }
};

export const addDragAndDropListeners = () => {
  addOrRemoveJustImageClass();
  addDragAndDropSectionsListener();
  addDragAndDropLinesListener();
  addDragAndDropWidgetsListener();
};

export const resetListeners = () => {
  addSectionsListeners();
  addColumnsListeners();
  addWidgetsListeners();
  addDragAndDropListeners();

  // Unlock click on widgets and sections
  const template = getTemplateIframeDocument();
  // eslint-disable-next-line no-unused-expressions
  template.querySelector('body')?.classList.remove('iframe-locked');
};

const embedContainerListenerClick = (event: any) => {
  event.preventDefault();
  const element = event.currentTarget;
  checkIfSavePointNeeded().then(() => {
    // Create new selector
    if (element.hasAttribute('id') && element.getAttribute('id') !== '') {
      setTemplateConfigurationKeyInState('embedSelector', `${element.tagName.toLowerCase()}#${element.id}`);
    } else if (element.classList.length > 0) {
      element.classList.remove(BUILDER_EMBED_CONTAINER_HOVER_CLASS);
      setTemplateConfigurationKeyInState('embedSelector', `${element.tagName.toLowerCase()}.${Array.from(element.classList).join('.')}`);
    }

    // Remove all listeners on elements
    const template = getTemplateIframeDocument();
    const elements = Array.from(template.querySelectorAll(BUILDER_EMBED_TEMPLATE_CONTAINERS) ?? []);
    elements.forEach((el) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      el.removeEventListener('mouseover', embedContainerListenerMouseover);

      // Trigger mouseout event to remove click event listener
      const mouseoutEvent = new Event('mouseout');
      el.dispatchEvent(mouseoutEvent);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      el.removeEventListener('mouseout', embedContainerListenerMouseout);
    });

    // Remove hover class if needed
    Array.from(template.querySelectorAll(`.${BUILDER_EMBED_CONTAINER_HOVER_CLASS}`) ?? []).forEach((el) => {
      el.classList.remove(BUILDER_EMBED_CONTAINER_HOVER_CLASS);
    });

    // Insert template in new selector
    insertEmbedTemplateInSelectedElement();

    // Save new selector in HTML
    const selector = state.template?.configuration?.embedSelector;
    const spmBody = template.querySelector('#spm_body');
    if (spmBody) {
      spmBody.setAttribute('data-target-element', selector);
    }

    // Reset embed position chooser and re-open left panel
    store.commit('liveEditor/toggleEmbedPositionChooser', false);
    togglePanel('widgetsPanel');

    // Reset iframe
    resetIframe();

    // TODO save page url in spm_body attribute data-target-url (window.btoa)

    // Update state
    updateSectionsInState(false);

    // Create history point of save
    createHistory(HistoryType.EMBED_CHANGE_POSITION);
  });
};

const embedContainerListenerMouseover = (event: any) => {
  event.stopPropagation();
  const element: Element = event.currentTarget;
  element.classList.add(BUILDER_EMBED_CONTAINER_HOVER_CLASS);
  element.addEventListener('click', embedContainerListenerClick);
};

const embedContainerListenerMouseout = (event: any) => {
  event.stopPropagation();
  const element: Element = event.currentTarget;
  element.classList.remove(BUILDER_EMBED_CONTAINER_HOVER_CLASS);
  element.removeEventListener('click', embedContainerListenerClick);
};

/**
 * Add listeners on elements in which the user can place an embed template
 */
export const addEmbedContainersListeners = () => {
  // We remove the current embedded element
  const template = getTemplateIframeDocument();
  const embedElement = template?.querySelector('#spm_body');

  if (embedElement) {
    embedElement.remove();
  }

  // We add mouseenter event on elements
  const elements = Array.from(template.querySelectorAll(BUILDER_EMBED_TEMPLATE_CONTAINERS) ?? []);
  elements.forEach((element) => {
    let count = 0;

    if (element.classList.length > 0) {
      Array.from(element.classList).every((className) => {
        if (className !== '' && !className.match(/[^a-z0-9_-]/i)) {
          count = Array.from(template.querySelectorAll(`.${className}`) ?? []).length;

          if (count === 1) {
            return false;
          }
        }

        return true;
      });
    }

    if (element.id !== '' || count === 1) {
      element.addEventListener('mouseover', embedContainerListenerMouseover);
      element.addEventListener('mouseout', embedContainerListenerMouseout);
    }
  });
};
