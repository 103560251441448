import { ActionContext } from 'vuex';

import {
  FieldsGroup,
  LiveEditorState,
  ParserFieldGroupObject,
  ParserFieldObject,
  ParserObject,
  ParserStructureObject,
  SelectedStructure,
  State,
  TabItem,
  WidgetTypeEnum,
  ActiveLiveEditorItemData,
  FieldConfig,
  Property,
  TemplateStructureEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  hideLeftToolbar,
  setActiveSection,
  TemplateEditorState,
  togglePanel,
} from '@/composables/template-editor/TemplateEditor';
// eslint-disable-next-line import/no-cycle
import getTemplateConfiguration from '@/components/template-builder/config/templates-config';
// eslint-disable-next-line import/no-cycle
import {
  getTemplateParentTypeByType,
  isPushTemplate,
  isSmsTemplate,
  replaceSelectors,
} from '@/components/template-builder/utils/helpers';
// eslint-disable-next-line import/no-cycle
import {
  addValuesToProperties,
  computeActiveSection,
  getChildrenStructure,
  getChildrenType,
  getParentClosestStructure,
} from '@/components/template-builder/utils/parser';

const initField = (item: FieldConfig, fieldGroupSelector: string, structureId: string, parentColumnId: string, parentLineId: string): ParserFieldObject => {
  const field: ParserFieldObject = { selector: '', properties: [] };

  let propertySelector = replaceSelectors(item.selector, '{SELECTOR}', fieldGroupSelector);
  propertySelector = replaceSelectors(propertySelector, '{ID}', structureId);
  propertySelector = replaceSelectors(propertySelector, '{COLUMN_ID}', parentColumnId);
  propertySelector = replaceSelectors(propertySelector, '{LINE_ID}', parentLineId);

  if (item.properties) {
    field.properties = addValuesToProperties(propertySelector, item.properties, structureId);
  }
  field.selector = propertySelector;
  return field;
};

export default {
  namespaced: true,
  state: (): LiveEditorState => ({
    selectedStructure: null,
    activeItemData: null,
    loadSectionPanel: { visible: false },
    saveSectionModalPayload: { visible: false, selector: null },
    enableEmbedPositionChooser: false,
    websiteContents: {},
    isSliderDragged: false,
    isChooseZipFileModalVisible: false,
    selectedWidget: null,
    selectedWidgetId: '',
    selectedColumnId: '',
  }),

  getters: {
    getSelectedStructure: (state: LiveEditorState) => state.selectedStructure,

    getSelectedStructureConfig: (state: LiveEditorState) => state.selectedStructure?.config,

    getActiveItemData: (state: LiveEditorState) => state.activeItemData,

    getActiveItemSelector: (state: LiveEditorState) => state.activeItemData?.selector,

    getActiveEmbedPositionChooser: (state: LiveEditorState) => state.enableEmbedPositionChooser,

    getWebsiteContents: (state: LiveEditorState) => state.websiteContents,

    getSaveSectionSelector: (state: LiveEditorState) => state.saveSectionModalPayload.selector,

    getIsSliderDragged: (state: LiveEditorState) => state.isSliderDragged,

    getIsChooseZipFileModalVisible: (state: LiveEditorState) => state.isChooseZipFileModalVisible,

    getSelectedWidget: (state: LiveEditorState) => state.selectedWidget,

    getSelectedWidgetId: (state: LiveEditorState) => state.selectedWidgetId,

    getSelectedColumnId: (state: LiveEditorState) => state.selectedColumnId,
  },

  mutations: {
    setSelectedStructure: (state: LiveEditorState, structure: SelectedStructure) => {
      state.selectedStructure = { ...structure };
    },

    resetSelectedStructure: (state: LiveEditorState) => {
      state.selectedStructure = null;
    },

    setActiveItemData: (state: LiveEditorState, activeItemData: ActiveLiveEditorItemData) => {
      state.activeItemData = activeItemData;
    },

    resetActiveItemData: (state: LiveEditorState) => {
      state.activeItemData = null;
    },

    showLoadSectionPanel: (state: LiveEditorState) => {
      state.loadSectionPanel.visible = true;

      hideLeftToolbar().then(() => {
        togglePanel('loadSectionPanel');
      });
    },

    hideLoadSectionPanel: (state: LiveEditorState) => {
      state.loadSectionPanel.visible = false;

      hideLeftToolbar();
    },

    showSaveSectionModal: (state: LiveEditorState, selector: string) => {
      state.saveSectionModalPayload = { visible: true, selector };
    },

    hideSaveSectionModal: (state: LiveEditorState) => {
      state.saveSectionModalPayload = { visible: false, selector: null };
    },

    toggleEmbedPositionChooser: (state: LiveEditorState, active: boolean) => {
      state.enableEmbedPositionChooser = active;
    },

    setWebsiteContents: (state: LiveEditorState, payload: { url: string; content: string }) => {
      state.websiteContents[payload.url] = payload.content;
    },

    resetWebsiteContents: (state: LiveEditorState) => {
      state.websiteContents = {};
    },

    setIsSliderDragged: (state: LiveEditorState, payload: boolean) => {
      state.isSliderDragged = payload;
    },

    setIsChooseZipFileModalVisible: (state: LiveEditorState, payload: boolean) => {
      state.isChooseZipFileModalVisible = payload;
    },

    setSelectedWidget: (state: LiveEditorState, payload: boolean) => {
      state.selectedWidget = payload;
    },

    setSelectedWidgetId: (state: LiveEditorState, payload: string) => {
      state.selectedWidgetId = payload;
    },

    setSelectedColumnId: (state: LiveEditorState, payload: string) => {
      state.selectedColumnId = payload;
    },
  },

  actions: {
    prepareStructureConfiguration: (
      { commit }: ActionContext<LiveEditorState, State>,
      { identifier, type, widgetType = null }: { identifier: string; type: string; widgetType: WidgetTypeEnum|null },
    ) => {
      if (!TemplateEditorState.template) return; // No active template

      setActiveSection(computeActiveSection(type, identifier)); // Set active section in state (usefull to get/set css values in correct stylesheet)

      const parserValues: ParserObject = {};
      const templateParentType = getTemplateParentTypeByType(TemplateEditorState.template.type);
      const config = getTemplateConfiguration(templateParentType, widgetType, TemplateEditorState.template.informations.imported)[type];

      if (!config) return; // Configuration not found for this template

      // We get the parent column and parent line to replace their IDs if element is a widget
      const parentColumnId = widgetType ? getParentClosestStructure(identifier, 'column') ?? null : null;
      const parentLineId = widgetType ? getParentClosestStructure(identifier, 'line') ?? null : null;

      config.items.forEach((tabItem: TabItem) => {
        const structureId = getParentClosestStructure(identifier, tabItem.tabItemType) ?? identifier;
        if (structureId) {
          parserValues[tabItem.label] = { items: {} };
          parserValues[tabItem.label].selector = `#${structureId}`;
          parserValues[tabItem.label].childrenStructureCount = getChildrenStructure(structureId, getChildrenType(tabItem.tabItemType, templateParentType)).length;

          const tabStructure: ParserStructureObject = {};
          (tabItem.tabPanel?.items || []).forEach((groupItem: FieldsGroup | TabItem) => {
            if (Object.prototype.hasOwnProperty.call(groupItem, 'tabPanel')) {
              // Is TabItem
              const groupTabItem = groupItem as TabItem;
              // eslint-disable-next-line no-unused-expressions
              groupTabItem.tabPanel?.items.forEach((itemGroupTabItem) => {
                const fieldGroup = itemGroupTabItem as FieldsGroup;
                let fieldGroupSelector = replaceSelectors(fieldGroup.selector || '', '{ID}', structureId);
                fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{COLUMN_ID}', parentColumnId ?? structureId);
                fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{LINE_ID}', parentLineId ?? structureId);
                const group: ParserFieldGroupObject = {};
                (fieldGroup.items || []).forEach((item) => {
                  const field: ParserFieldObject = initField(item, fieldGroupSelector, structureId, parentColumnId ?? structureId, parentLineId ?? structureId);
                  group[item.label] = field;
                });
                tabStructure[fieldGroup.label] = group;
              });
            } else if (Object.prototype.hasOwnProperty.call(groupItem, 'items')) {
              // Is FieldsGroup
              const fieldGroup = groupItem as FieldsGroup;
              let fieldGroupSelector = replaceSelectors(fieldGroup.selector || '', '{ID}', structureId);
              fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{COLUMN_ID}', parentColumnId ?? structureId);
              fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{LINE_ID}', parentLineId ?? structureId);
              const group: ParserFieldGroupObject = {};
              (fieldGroup.items || []).forEach((item) => {
                const field: ParserFieldObject = initField(item, fieldGroupSelector, structureId, parentColumnId ?? structureId, parentLineId ?? structureId);
                group[item.label] = field;
              });
              tabStructure[fieldGroup.label] = group;
            }
          });
          parserValues[tabItem.label].items = tabStructure;
        }
      });
      commit('setSelectedStructure', {
        identifier,
        type,
        config,
        parserValues,
      });
    },
    refreshWidgetFields: ({ state }: ActionContext<LiveEditorState, State>) => {
      if (!state.selectedStructure) return;
      const config = state.selectedStructure?.config;
      if (!config) return;
      if (!TemplateEditorState.template) return; // No active template

      const templateParentType = getTemplateParentTypeByType(TemplateEditorState.template.type);

      // eslint-disable-next-line max-len
      const openedTab = config.items.filter((tabItem: TabItem) => ((isPushTemplate(templateParentType) || isSmsTemplate(templateParentType)) && tabItem.tabItemType === TemplateStructureEnum.PAGE) || tabItem.tabItemType === TemplateStructureEnum.WIDGET)[0];
      const structureId = getParentClosestStructure(state.selectedStructure?.identifier ?? '', openedTab.tabItemType) ?? state.selectedStructure.identifier;

      // We get the parent column and parent line to replace their IDs if element is a widget
      const parentColumnId = getParentClosestStructure(structureId, 'column') ?? null;
      const parentLineId = getParentClosestStructure(structureId, 'line') ?? null;

      (openedTab.tabPanel?.items || []).forEach((fieldGroup: FieldsGroup) => {
        let fieldGroupSelector = replaceSelectors(fieldGroup.selector || '', '{ID}', structureId);
        fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{COLUMN_ID}', parentColumnId ?? structureId);
        fieldGroupSelector = replaceSelectors(fieldGroupSelector, '{LINE_ID}', parentLineId ?? structureId);
        (fieldGroup.items || []).forEach((item: FieldConfig) => {
          let propertySelector = replaceSelectors(item.selector, '{SELECTOR}', fieldGroupSelector);
          propertySelector = replaceSelectors(propertySelector, '{ID}', structureId);
          propertySelector = replaceSelectors(propertySelector, '{COLUMN_ID}', parentColumnId ?? structureId);
          propertySelector = replaceSelectors(propertySelector, '{LINE_ID}', parentLineId ?? structureId);
          item.properties.forEach((property: Property) => {
            const updatedProperty = property;
            updatedProperty.value = updatedProperty.getter(propertySelector, updatedProperty.name, structureId);
          });
        });

        if ('tabPanel' in fieldGroup && fieldGroup.tabPanel && fieldGroup.tabPanel.items.length > 0) {
          (fieldGroup.tabPanel.items || []).forEach((subGroup: FieldsGroup) => {
            (subGroup.items || []).forEach((subItem: FieldConfig) => {
              let propertySelector = replaceSelectors(subItem.selector, '{SELECTOR}', fieldGroupSelector);
              propertySelector = replaceSelectors(propertySelector, '{ID}', structureId);
              propertySelector = replaceSelectors(propertySelector, '{COLUMN_ID}', parentColumnId ?? structureId);
              propertySelector = replaceSelectors(propertySelector, '{LINE_ID}', parentLineId ?? structureId);
              subItem.properties.forEach((property: Property) => {
                const updatedProperty = property;
                updatedProperty.value = updatedProperty.getter(propertySelector, updatedProperty.name, structureId);
              });
            });
          });
        }
      });
    },
  },
};
