
import {
  computed, ComputedRef, defineComponent, Ref, ref, watch,
} from 'vue';
import { Request } from '@/composables/GraphQL';
import {
  getTemplateIframeDocument,
  isDisplayTemplate,
  isEmailTemplate,
  isFacebookTemplate,
  isPushTemplate,
  isSmsTemplate,
  reconstructDynamicPersistentMobileCss,
} from '@/components/template-builder/utils/helpers';
import { UserState } from '@/composables/User';
import { getParents, showToastError } from '@/helpers';
import { HistoryType, Section } from '@/types';
import {
  getActiveTemplateFullHtml,
  getActiveTemplateStylesheets,
  insertEmbedTemplateInDom,
  resetActiveSection,
  resetIframe,
  setActiveSection,
  setTemplateConfigurationKeyInState,
  templateChecksOnOpening,
  TemplateEditorState as state,
  updateSectionsInState,
  getTranslationIdFromImportedTemplateElement,
  setSelectedTranslationId,
  fixBrokenSmartProductsLists,
  backwardCompatibilityMediaRestrictionClasses,
  checkMultipleClassesSelectors,
  removeBrFromStylesheets,
  fixBrokenWidgetVoucher,
  showIframeTemplateWrapper, hideIframeTemplateWrapper,
} from '@/composables/template-editor/TemplateEditor';
import { TemplateTypeEnum } from '@/composables/shop/Templates';
import {
  AutomatedScenarioState,
} from '@/composables/AutomatedScenarios';
import Loader from '@/components/layout/Loader.vue';
import UrlNavBar from '@/components/UrlNavBar.vue';
import {
  BUILDER_SELECTOR_DISPLAY_DELETABLE,
  TEMPLATE_COLUMN_IDENTIFIER_PREFIX,
  TEMPLATE_SECTION_CLASS,
} from '@/components/template-builder/utils/constants';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@/i18n';
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';
import { useStore } from '@/store';
import {
  addOrUpdateDynamicStyle,
  cleanupDynamicStyle,
  getDynamicStylesForActiveSection,
  getTemplateColumns,
} from '@/components/template-builder/utils/parser';

export default defineComponent({
  name: 'TemplateLiveView',

  components: {
    UrlNavBar,
    Loader,
  },

  directives: {
    'iframe-listener': {
      mounted(el, binding) {
        hideIframeTemplateWrapper();
        el.contentWindow.addEventListener('mouseup', binding.value);
      },

      beforeUnmount(el, binding) {
        el.contentWindow.removeEventListener('mouseup', binding.value);
      },
    },
  },

  props: {
    templateId: {
      type: Number,
      required: true,
    },

    templateType: {
      type: String,
      required: true,
    },

    width: {
      type: String,
      required: true,
    },
  },

  emits: {
    'on-iframe-loaded': null,
  },

  setup(props, context) {
    const store = useStore();
    const parser = new DOMParser();
    const templateTitle = `template-${props.templateId}`;
    const iframe = ref<HTMLIFrameElement>(document.createElement('iframe'));
    const content: Ref<Document | null> = ref(null);
    const websiteContent: ComputedRef<{[key: string]: string}> = computed(() => store.getters['liveEditor/getWebsiteContents']);
    const srcDoc = computed(() => content.value && content.value.documentElement.outerHTML);
    const isDisplay: ComputedRef<boolean> = computed(() => isDisplayTemplate(props.templateType));
    const urlShop: Ref<string> = ref(UserState.activeShop?.url ?? '');
    const displayUrl: Ref<string> = ref(AutomatedScenarioState.defaultDisplayUrl || (UserState.activeShop?.url ?? ''));
    const currentUrl: Ref<string> = ref('');
    const loading: Ref<boolean> = ref(true);

    /**
     * Rewrite links' URL in document
     */
    const rewriteLinksInDocument = () => {
      const template = getTemplateIframeDocument();
      // Expression régulière pour valider une URL
      const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:\d+)?(\/[^\s]*)?$/;
      if (template) {

        let baseShopUrl: URL | boolean = false;
        if (typeof UserState.activeShop?.url === 'string') {
          baseShopUrl = urlPattern.test(UserState.activeShop?.url) ? new URL(UserState.activeShop?.url) : false;
        }
        if (baseShopUrl && baseShopUrl.hostname) {
          const links = Array.from(template.querySelectorAll('a') ?? []);
          links.forEach((link: HTMLElement) => {
            if (link.hasAttribute('href') && (new RegExp('^http', 'i')).test(link.getAttribute('href') ?? '')) {
              const href = link.getAttribute('href');
              let baseDomainOfLink: URL | boolean = false;
              if (typeof href === 'string') {
                baseDomainOfLink = urlPattern.test(href) ? new URL(href, window.location.origin) : false;
              }
              // eslint-disable-next-line max-len
              if (typeof baseDomainOfLink === 'object' && typeof baseShopUrl === 'object' && baseDomainOfLink.hostname === baseShopUrl.hostname && baseDomainOfLink.protocol !== baseShopUrl.protocol) {
                // Switch link to https protocol
                baseDomainOfLink.protocol = 'https:';
                link.setAttribute('href', baseDomainOfLink.toString());
              }
            }
          });
        }
      }
    };

    /**
     * If body is not visible, we add CSS rules to display it
     * @param template
     */
    const checkBodyVisibility = (template: Document) => {
      const { body, head } = template;

      if (body.style.display === 'none') {
        head.append('<style>body { display : unset !important; }</style>');
      }

      if (body.style.overflow === 'none') {
        body.style.overflow = 'auto';
      }

      const [html] = template.getElementsByTagName('html');
      html.style.visibility = 'visible';
      html.style.display = 'block';
      body.style.visibility = 'visible';
      body.style.display = 'block';
    };

    /**
     * Look for styles or scripts added after page load to rewrite their URL
     * @param template
     */
    const checkForNewStylesOrScripts = (template: Document) => {
      // We check if scripts or CSS styles have been added after loading and are using relative path
      const regexHttp = new RegExp('^(http(s)?:)?//');
      Array.from(template.querySelectorAll('link:not([data-do-not-rewrite-url="1"])') ?? []).forEach((element) => {
        let currentHref = element.getAttribute('href');

        if (currentHref && !regexHttp.test(currentHref)) {
          if (currentHref.charAt(0) === '/' && urlShop.value.charAt(urlShop.value.length - 1) === '/') {
            currentHref = currentHref.substr(1, currentHref.length - 1);
          }

          currentHref = urlShop.value + currentHref;
          element.setAttribute('href', currentHref);
        }
      });

      Array.from(template.querySelectorAll('script:not([data-do-not-rewrite-url="1"])') ?? []).forEach((element) => {
        let currentSrc = element.getAttribute('src');

        if (currentSrc && !regexHttp.test(currentSrc)) {
          if (currentSrc.charAt(0) === '/' && urlShop.value.charAt(urlShop.value.length - 1) === '/') {
            currentSrc = currentSrc.substr(1, currentSrc.length - 1);
          }

          currentSrc = urlShop.value + currentSrc;
          element.setAttribute('src', currentSrc);
        }
      });
    };

    /**
     * Remove elements in document which can prevent template's edition
     * @param template
     */
    const removeDangerousElements = (template: Document) => {
      // Force overflow auto on html and body tag
      // eslint-disable-next-line no-param-reassign
      template.getElementsByTagName('html')[0].style.overflow = 'auto';
      // eslint-disable-next-line no-param-reassign
      template.getElementsByTagName('body')[0].style.overflow = 'auto';

      Array.from(template.querySelectorAll('div') ?? []).filter((div: HTMLElement) => {
        const idElement = (div.hasAttribute('id')) ? (div.id.indexOf('spm') >= 0) : false;
        const classElement = (div.classList.length > 0) ? ((div.getAttribute('class') ?? '').indexOf('spm') >= 0) : false;
        const nameElement = (div.hasAttribute('name')) ? ((div.getAttribute('name') ?? '').indexOf('spm') >= 0) : false;
        const isSpmElement = (idElement || classElement || nameElement);
        const isDeletable = div.matches(BUILDER_SELECTOR_DISPLAY_DELETABLE);
        const top = window.getComputedStyle(div, null).getPropertyValue('top');
        const left = window.getComputedStyle(div, null).getPropertyValue('left');
        const position = window.getComputedStyle(div, null).getPropertyValue('position');

        return (
          isDeletable
          || (
            !isSpmElement
            && (
              (
                div.style.position === 'fixed'
                || div.style.position === 'absolute'
                || position === 'fixed'
                || position === 'absolute'
              )
              && (parseInt(div.style.top, 10) < 100 || parseInt(top, 10) < 100)
              && (parseInt(div.style.left, 10) < 100 || parseInt(left, 10) < 100)
              && div.offsetHeight > 170
            )
          )
        );
      }).forEach((div: HTMLElement) => div.remove());

      setInterval(() => {
        // On vérifie si le plugin uniform n'a pas modifié les champs du formulaire
        if (template.querySelectorAll(".spm_columns div[id^='uniform-']")) {
          // eslint-disable-next-line no-inner-declarations
          function haveParentFunc(el: any, selector: string) {
            const parents = [];
            let i = 0;
            // eslint-disable-next-line no-cond-assign,no-param-reassign
            while ((el = el.parentNode) && el !== document) {
              // On ne remonte pas plus de 4 parents
              if (i === 4) {
                break;
              }
              if (!selector || el.matches(selector)) {
                parents.unshift(el);
                break;
              }
              i += 1;
            }
            return (parents.length ? parents : false);
          }
          Array.from(template.querySelectorAll(".spm_columns div[id^='uniform-']")).forEach((e: any) => {
            const haveParent = haveParentFunc(e, "div[id^='uniform-']");
            if (!haveParent) {
              const input = e.querySelectorAll(':scope input');
              if (typeof input === 'object' && typeof input[0] !== 'undefined' && typeof e === 'object' && typeof e.parentNode === 'object') {
                e.parentNode.prepend(input[0]);
                e.remove();
              }
            }
          });
        }
      }, 500);
    };

    /**
     * Remove mso-line-height-alt styles in style elements
     */
    const removeMsoLineHeightAltPropertyFromStyles = (template: Document) => {
      // Get styles of template
      const stylesOfDocument = template.querySelectorAll('style');

      if (stylesOfDocument && stylesOfDocument.length > 0) {
        Array.from(stylesOfDocument).forEach((styleOfDocument) => {
          let cssRules = styleOfDocument.innerText;
          cssRules = cssRules.replace(new RegExp('mso-line-height-alt:[\\\\s]*(?!0(%|px|!))[0-9]+(!important|px|%);?', 'gi'), '');
          // eslint-disable-next-line no-param-reassign
          styleOfDocument.innerText = cssRules;
        });
      }

      // Reset current active section if any and update all sections in state
      resetActiveSection();
      updateSectionsInState();
    };

    /**
     * Execute functions after loading a display template
     */
    const applyPostLoadingChecksForDisplay = () => {
      const template = getTemplateIframeDocument();

      if (template) {
        checkBodyVisibility(template);
        checkForNewStylesOrScripts(template);
        removeDangerousElements(template);
        fixBrokenSmartProductsLists();
        fixBrokenWidgetVoucher();
        cleanupDynamicStyle();
      }
    };

    /**
     * Execute functions after loading an email template
     */
    const applyPostLoadingChecksForEmail = () => {
      const template = getTemplateIframeDocument();

      if (template) {
        removeMsoLineHeightAltPropertyFromStyles(template);
        backwardCompatibilityMediaRestrictionClasses(template);
        cleanupDynamicStyle();
        reconstructDynamicPersistentMobileCss();
        checkMultipleClassesSelectors();
        fixBrokenSmartProductsLists();
        removeBrFromStylesheets();
      }
    };

    const createIframeContent = async () => {
      // add html to body
      const spmTemplate = document.createElement('spm-template');
      spmTemplate.innerHTML = getActiveTemplateFullHtml();

      if (isDisplay.value) {
        // Get default url from template
        let url = spmTemplate?.querySelector('#spm_body')?.getAttribute('data-target-url');

        if (url) {
          url = atob(url);

          // We check if the url belongs to the shop, otherwise we display the homepage
          if ((new RegExp(urlShop.value.replace(new RegExp('^https?:'), ''))).test(url.replace(new RegExp('^https?:'), ''))) {
            if (url !== currentUrl.value) {
              // If url is different from the old one, we change
              displayUrl.value = url.replace(new RegExp('^http:'), 'https:');
            }
          } else {
            // Url doesn't belong to shop, we change it by shop's url
            const body = spmTemplate?.querySelector('#spm_body');
            if (body) {
              body.setAttribute('data-target-url', btoa(urlShop.value));
            }
          }
        }

        if (displayUrl.value === currentUrl.value) {
          loading.value = false;
        }
        // Refresh website content only if url changes
        const httpUrl = `http://${displayUrl.value.replace(/^(https?:\/\/|\/\/)/, '')}`;
        const httpsUrl = `https://${displayUrl.value.replace(/^(https?:\/\/|\/\/)/, '')}`;

        const query = 'query ( $url: String) { TemplatesShopWebsiteContent(shopUrl: $url) }';

        const httpQuery = Request<string>({
          name: 'TemplatesShopWebsiteContent',
          query,
          variables: {
            url: httpUrl,
          },
        });
        const httpsQuery = Request<string>({
          name: 'TemplatesShopWebsiteContent',
          query,
          variables: {
            url: httpsUrl,
          },
        });

        const results = await Promise.all([httpsQuery, httpQuery]);
        const result = results.find((item: any) => item.data && !item.err);
        if (result) {
          currentUrl.value = displayUrl.value;
          store.commit('liveEditor/setWebsiteContents', { url: currentUrl.value, content: result.data });
        } else {
          await showToastError('CLIENT_WEBSITE_CONNECTION_ERROR');
          loading.value = false;
        }
        content.value = parser.parseFromString(websiteContent.value[currentUrl.value], 'text/html');

        // Add extra css and js to iframe
        const spmApiCss = document.createElement('link');
        spmApiCss.setAttribute('rel', 'stylesheet');
        spmApiCss.setAttribute('data-do-not-rewrite-url', '1');
        spmApiCss.setAttribute('href', 'https://app-spm.com/css/SpmAPI.css');
        content.value.head.appendChild(spmApiCss);
        const spmApiJs = document.createElement('script');
        spmApiJs.setAttribute('data-do-not-rewrite-url', '1');
        spmApiJs.setAttribute('src', 'https://app-spm.com/procedural_api/SpmAPI.min.js');
        content.value.head.appendChild(spmApiJs);
        const spmFixWebsiteJs = document.createElement('script');
        spmFixWebsiteJs.setAttribute('data-do-not-rewrite-url', '1');
        spmFixWebsiteJs.setAttribute('src', '/js/fixWebsiteJs.js');
        content.value.body.appendChild(spmFixWebsiteJs);
        const script = document.createElement('script');
        script.textContent = `XMLHttpRequestRelativeToAbsolute('${currentUrl.value}');`;
        content.value.body.appendChild(script);
      } else {
        content.value = document.implementation.createHTMLDocument();
      }

      const { lang } = UserState.user;
      const languageToUse = lang && SUPPORTED_LOCALES.includes(lang) ? lang : DEFAULT_LOCALE;

      // Add base CSS styles for live edit
      const baseIframeCss = document.createElement('link');
      baseIframeCss.setAttribute('rel', 'stylesheet');
      baseIframeCss.setAttribute('data-do-not-rewrite-url', '1');
      baseIframeCss.setAttribute('href', '/css/template-builder/baseIframe.css?20240605');
      content.value.head.appendChild(baseIframeCss);

      // Add CSS styles by lang for live edit
      const baseIframeLangCss = document.createElement('link');
      baseIframeLangCss.setAttribute('rel', 'stylesheet');
      baseIframeLangCss.setAttribute('data-do-not-rewrite-url', '1');
      baseIframeLangCss.setAttribute('href', `/css/template-builder/baseIframe-${languageToUse}.css`);
      content.value.head.appendChild(baseIframeLangCss);

      // Add CSS styles according to template's type
      if (isPushTemplate(state.template?.type)) {
        const baseIframeCssPush = document.createElement('link');
        baseIframeCssPush.setAttribute('rel', 'stylesheet');
        baseIframeCssPush.setAttribute('data-do-not-rewrite-url', '1');
        baseIframeCssPush.setAttribute('href', '/css/template-builder/baseIframePushNotifications.css');
        content.value.head.appendChild(baseIframeCssPush);
      } else if (isSmsTemplate(state.template?.type)) {
        const baseIframeCssSms = document.createElement('link');
        baseIframeCssSms.setAttribute('rel', 'stylesheet');
        baseIframeCssSms.setAttribute('data-do-not-rewrite-url', '1');
        baseIframeCssSms.setAttribute('href', '/css/template-builder/baseIframeSms.css');
        content.value.head.appendChild(baseIframeCssSms);
      } else if (isFacebookTemplate(state.template?.type)) {
        const baseIframeCssFacebookMessenger = document.createElement('link');
        baseIframeCssFacebookMessenger.setAttribute('rel', 'stylesheet');
        baseIframeCssFacebookMessenger.setAttribute('data-do-not-rewrite-url', '1');
        baseIframeCssFacebookMessenger.setAttribute('href', '/css/template-builder/baseIframeFacebookMessenger.css');
        content.value.head.appendChild(baseIframeCssFacebookMessenger);
      } else if (isDisplayTemplate(state.template?.type)) {
        const spmFont = document.createElement('link');
        spmFont.setAttribute('rel', 'stylesheet');
        spmFont.setAttribute('data-do-not-rewrite-url', '1');
        spmFont.setAttribute('href', 'https://media.shopimind.io/app/css/spm_fonts/css/spm_font.css');
        content.value.head.appendChild(spmFont);
        const metaPolicy = document.createElement('meta');
        metaPolicy.setAttribute('http-equiv', 'Content-Security-Policy');
        metaPolicy.setAttribute('content', 'upgrade-insecure-requests');
        content.value.head.appendChild(metaPolicy);
      }

      // Add CSS if imported template
      if (state.template?.informations.imported) {
        const baseIframeImportedCss = document.createElement('link');
        baseIframeCss.setAttribute('rel', 'stylesheet');
        baseIframeCss.setAttribute('data-do-not-rewrite-url', '1');
        baseIframeCss.setAttribute('href', '/css/template-builder/baseIframeImported.css');
        content.value.head.appendChild(baseIframeImportedCss);
      }

      // Add template to content
      // add stylesheets to head
      getActiveTemplateStylesheets().forEach((stylesheet: HTMLStyleElement) => {
        if (content.value) content.value.head.appendChild(stylesheet);
      });

      // Add element ID on body (design element)
      if (isEmailTemplate(state.template?.type)) {
        const designId = state.template?.sections.filter((section: Section) => section.id_template_elements === state.template?.content.design)[0].id_template_elements;
        if (designId) {
          content.value.body.setAttribute('data-spmelementid', designId.toString());
        }
      }

      // If embed template, get selector and position in HTML code, store in state and insert at the right place in the page
      if (state.template?.type === TemplateTypeEnum.EMBED) {
        const selector = spmTemplate?.querySelector('#spm_body')?.getAttribute('data-target-element') ?? 'body';
        const position = spmTemplate?.querySelector('#spm_body')?.getAttribute('data-target-element-position') ?? 'append';
        setTemplateConfigurationKeyInState('embedSelector', selector);
        setTemplateConfigurationKeyInState('embedPosition', position);
        insertEmbedTemplateInDom(content.value, spmTemplate, selector, position);
      } else {
        content.value.body.appendChild(spmTemplate);
      }

      content.value = templateChecksOnOpening(content.value);
    };

    const handleChangeUrl = async (url: string) => {
      // Change url after pressing "enter" or clicking the validation button if it matches with shop's url or clicking on a link in the document
      if ((new RegExp(urlShop.value.replace(new RegExp('^https?:'), ''))).test(url.replace(new RegExp('^https?:'), ''))) {
        checkIfSavePointNeeded().then(() => {
          loading.value = true;
          displayUrl.value = url.replace(new RegExp('^http:'), 'https:');

          const template = getTemplateIframeDocument();
          if (template) {
            const body = template.querySelector('#spm_body');
            if (body) {
              body.setAttribute('data-target-url', btoa(displayUrl.value));
            }
          }

          // Mark section as active
          const section = template?.querySelector('[data-spmelementid]');
          if (section) {
            const sectionId = section.getAttribute('data-spmelementid');

            if (sectionId) {
              // Mark section as active
              setActiveSection(parseInt(sectionId, 10));
            }
          }

          // Update state
          updateSectionsInState(false);

          createHistory(HistoryType.DISPLAY_CHANGE_URL);

          resetActiveSection();

          createIframeContent();
        });
      } else {
        await showToastError('CLIENT_WEBSITE_CONNECTION_ERROR');
        loading.value = false;
      }
    };

    /**
     * Cancel click event on link and change iframe's URL
     */
    const interceptClicksOnLinksInDocument = () => {
      const template = getTemplateIframeDocument();

      if (template) {
        // Intercept click on links in document
        template.body.addEventListener('click', (e) => {
          let target = e.target as HTMLElement;
          let isLink = false;

          if (target) {
            // Check if target is a link or if one of its parents is a link
            while (target !== document.documentElement && target) {
              if (target.tagName === 'A') {
                // Prevent redirect
                e.preventDefault();
                e.stopPropagation();

                // Change url of iframe if we are on a display template
                if (target.hasAttribute('href') && isDisplayTemplate(state.template?.type)) {
                  handleChangeUrl(target.getAttribute('href') ?? '');
                }

                isLink = true;
                break;
              }

              target = target.parentNode as HTMLElement;
            }
          }

          return !isLink;
        });

        // Disable clicks on links inside template
        Array.from(template.body.querySelectorAll('#spm_body a') ?? []).forEach((link) => {
          link.addEventListener('click', (e) => {
            // Prevent redirect
            e.preventDefault();
            e.stopPropagation();
            return false;
          });
        });
      }
    };

    const interceptClicksOnImportedDocument = () => {
      const template = getTemplateIframeDocument();

      if (template) {
        template.body.addEventListener('click', (e) => {
          const target = e.target as HTMLElement;

          if (target) {
            let targetLink = target;
            // Check if target is a link or if one of its parents is a link
            while (targetLink && targetLink.tagName.toLowerCase() !== 'body' && targetLink.tagName.toLowerCase() !== 'spm-template') {
              if (targetLink.tagName.toLowerCase() === 'a') {
                // Prevent redirect
                e.preventDefault();
                e.stopPropagation();
                break;
              }

              targetLink = targetLink.parentElement as HTMLElement;
            }

            const translationId = getTranslationIdFromImportedTemplateElement(target);
            if (translationId) {
              setSelectedTranslationId(translationId);
            }
          }

          return true;
        });
      }
    };
    const onLoad = () => {
      if (iframe.value && srcDoc.value) {

        resetIframe();

        if (isDisplayTemplate(state.template?.type)) {
          rewriteLinksInDocument();
          interceptClicksOnLinksInDocument();
          applyPostLoadingChecksForDisplay();
        } else if (isEmailTemplate(state.template?.type)) {
          applyPostLoadingChecksForEmail();

          // Prevent redirect
          if (state.template?.informations.imported) {
            interceptClicksOnImportedDocument();
          }
        }
        showIframeTemplateWrapper();
        context.emit('on-iframe-loaded');
        loading.value = false;
      }
    };

    watch(() => props.templateId, (value, oldValue) => {
      if (value !== oldValue) {
        loading.value = true;

        // Reset iframe content
        content.value = null;

        // Recreate iframe content
        createIframeContent();
      }
    }, { immediate: true });

    const handleIFrameEvent = (event: any) => {
      if (event.type === 'mouseup') {
        store.commit('liveEditor/setIsSliderDragged', false);
      }
    };

    return {
      onLoad,
      handleChangeUrl,
      loading,
      templateTitle,
      srcDoc,
      isDisplay,
      displayUrl,
      urlShop,
      handleIFrameEvent,
    };
  },
});
